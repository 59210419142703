import { makeAutoObservable, runInAction } from "mobx";
import { MOCK_PREVIEW, mock3 } from "./const";
import { Modal, message } from "antd";
import { get, post } from "../../axios/axios";
import { CodeIcon, PicIcon, TableIcon } from "./Summary/icons";
import { CalculatorOutlined, InfoCircleOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

class SummaryStore {
  /** 论文题目 */
  majorName = "";
  /** 是否使用三级大纲 */
  useThreeOutline = true;
  /** 是否使用ultra版本 */
  useUltra = false;
  /** 是否正在生成大纲 */
  isLoadingSummary = false;
  /** 大纲数据 */
  paragraphs = null;
  /** 服务列表 */
  serviceList = [];

  paperInfo = null;
  /** 展示Ultra介绍弹窗 */
  showUltraDialog = false;
  isLoadingPreview = false;
  isExtraloading = false;
  nextPreviewLoading = false;
  hasPreviewEnd = false;
  categories = [];
  autoLightTypes = [];
  showCheckTableDialog = false;

  _tempAutoText = "";

  // 文献
  customReference = {
    checked: false,
    customReferenceDocument: "",
  };
  constructor() {
    makeAutoObservable(this);
    this.initApiVersion();
    this.getServiceList();
    this.getCatogories();
  }

  reset() {
    this.majorName = "";
    this.useThreeOutline = true;
    this.isLoadingSummary = false;
    this.paragraphs = null;
    this.serviceList = [];
    this.paperInfo = null;
    this.isLoadingPreview = false;
    this.isExtraloading = false;
    this.nextPreviewLoading = false;
    this.showUltraDialog = false;
    this.hasPreviewEnd = false;
    this.autoLightTypes = [];
    this._tempAutoText = "";
    this.showCheckTableDialog = false;
    this.customReference = { checked: false, customReferenceDocument: "" };
  }
  onAutoLightChange = (list) => {
    this.autoLightTypes = list;
  };
  getAutoText() {
    const TYPES_DESC = {
      CODE: "代码",
      TABLE: "表",
      DIAGRAM: "图",
      FORMULA: "公式",
    };
    return (
      (this.autoLightTypes &&
        this.autoLightTypes.map((i) => TYPES_DESC[i]).join(",")) ||
      ""
    );
  }
  initApiVersion() {
    this.useUltra = false;
  }
  toggleUseUltra() {
    if (this.isLoadingSummary) {
      message.warning("大纲生成中，请结束后再试");
      return;
    }
    this.reset();
    this.useUltra = !this.useUltra;
  }
  toggleUltraDialog() {
    this.showUltraDialog = !this.showUltraDialog;
  }
  hideUltraDialog() {
    this.showUltraDialog = false;
  }
  changeMajorName(v) {
    this.majorName = v.trim();
  }
  toggleThreeOutline() {
    if (this.isLoadingSummary) {
      message.warning("大纲生成中，请结束后再试");
      return;
    }
    this.useThreeOutline = !this.useThreeOutline;
  }
  addTitle(opts) {
    if (this.isLoadingPreview) {
      message.info("论文预览生成中，请结束后再试");
      return;
    }

    const {
      level,
      level1idx,
      level2idx,
      level3idx,
      inputMain,
      inputSub,
      isAfter,
    } = opts;
    let idxDiff = isAfter ? 1 : 0;
    if (level === 1) {
      this.paragraphs.splice(level1idx + idxDiff, 0, {
        chapter: inputMain,
        sections: [],
      });
    } else if (level === 2) {
      this.paragraphs[level1idx].sections.splice(level2idx + idxDiff, 0, {
        chapter: inputMain,
        chapterAbstract: inputSub,
        extraType: "NORMAL,CODE,TABLE,DIAGRAM,FORMULA",
        selected_extra: "",
        subsections: [],
      });
    } else if (level === 3) {
      this.paragraphs[level1idx].sections[level2idx].subsections.splice(
        level3idx + idxDiff,
        0,
        {
          chapter: inputMain,
          chapterAbstract: inputSub,
          extraType: "NORMAL,CODE,TABLE,DIAGRAM,FORMULA",
          selected_extra: "",
        }
      );
    }
  }
  editTitle(opts) {
    if (this.isLoadingPreview) {
      message.info("论文预览生成中，请结束后再试");
      return;
    }

    const { level, level1idx, level2idx, level3idx, inputMain, inputSub } =
      opts;
    if (level === 1) {
      this.paragraphs[level1idx] = {
        ...this.paragraphs[level1idx],
        chapter: inputMain,
      };
    } else if (level === 2) {
      this.paragraphs[level1idx].sections[level2idx] = {
        ...this.paragraphs[level1idx].sections[level2idx],
        chapter: inputMain,
        chapterAbstract: inputSub,
      };
    } else if (level === 3) {
      this.paragraphs[level1idx].sections[level2idx].subsections[level3idx] = {
        ...this.paragraphs[level1idx].sections[level2idx].subsections[
          level3idx
        ],
        chapter: inputMain,
        chapterAbstract: inputSub,
      };
    }
  }
  deleteTitle(opts) {
    if (this.isLoadingPreview) {
      message.info("论文预览生成中，请结束后再试");
      return;
    }

    const { level, level1idx, level2idx, level3idx, txt } = opts;
    Modal.confirm({
      title: "确认删除吗",
      content: `确认删除章节<${txt}>吗？章节不足可能会导致论文总体字数不足`,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        if (level === 1) {
          this.paragraphs.splice(level1idx, 1);
        } else if (level === 2) {
          this.paragraphs[level1idx].sections.splice(level2idx, 1);
        } else if (level === 3) {
          this.paragraphs[level1idx].sections[level2idx].subsections.splice(
            level3idx,
            1
          );
        }
      },
      onCancel: () => {},
    });
  }
  changeTitleExtra(opts) {
    if (this.isLoadingPreview) {
      message.info("论文预览生成中，请结束后再试");
      return;
    }

    const { v, level, level1idx, level2idx, level3idx } = opts;
    const { checked, type } = v;
    if (level === 1) {
    } else if (level === 2) {
      const origin =
        this.paragraphs[level1idx].sections[level2idx].selected_extra;
      this.paragraphs[level1idx].sections[level2idx] = {
        ...this.paragraphs[level1idx].sections[level2idx],
        selected_extra: modifySelectExtra({ origin, checked, type }),
      };
    } else if (level === 3) {
      const origin =
        this.paragraphs[level1idx].sections[level2idx].subsections[level3idx]
          .selected_extra;
      this.paragraphs[level1idx].sections[level2idx].subsections[level3idx] = {
        ...this.paragraphs[level1idx].sections[level2idx].subsections[
          level3idx
        ],
        selected_extra: modifySelectExtra({ origin, checked, type }),
      };
    }
  }

  /** 获取服务列表 */
  async getServiceList() {
    try {
      const result = await get("/ai/paper/add/services/list");
      this.serviceList =
        result?.data.map((it) => ({ ...it, checked: false })) || [];
    } catch (error) {
      console.error(error);
    }
  }

  onModalConfirmGetSummary(notiApi) {
    this.showCheckTableDialog = false;
    this.getSummary(notiApi);
  }

  /** 生成大纲 */
  async getSummary(notiApi, checkTable = false) {
    if (!this.majorName) {
      message.warning("输入论文题目后一键帮您生成大纲");
      return;
    }
    if (this.autoLightTypes.length === 0 && checkTable) {
      this.showCheckTableDialog = true;
      return;
    }
    const key = `getSummary`;
    this._tempAutoText = this.getAutoText();
    notiApi.info({
      key,
      message: "大纲开始生成",
      description: GetSummaryNotiDom(this.getAutoText()),
      className: styles.getSummaryNoti,
      duration: 0,
      icon: <InfoCircleOutlined />,
    });
    try {
      this.paragraphs = null;
      this.isLoadingSummary = true;

      const result = await post("/ai/paper/gen/outline", {
        subject: "11",
        majorName: this.majorName,
        threeOutline: this.useThreeOutline ? "y" : "n",
        version: this.useUltra ? "ultra" : "d",
        autoLightTypes:
          this.autoLightTypes && this.autoLightTypes.join
            ? this.autoLightTypes.join(",")
            : "",
      });
      this.paragraphs = result.data;
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        this.isLoadingSummary = false;
        setTimeout(() => {
          document
            .getElementById("summaryHeader")
            ?.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }, 700);
      notiApi.destroy(key);
    }
  }

  async getPreview() {
    try {
      this.isLoadingPreview = true;
      const result = await post("/ai/paper/gen", {
        subject: "11",
        majorName: this.majorName,
        threeOutline: this.useThreeOutline ? "y" : "n",
        version: this.useUltra ? "ultra" : "d",
        paperOutline: this.paragraphs,
        customReferenceDocument: this.customReference.checked
          ? this.customReference.customReferenceDocument
          : "",
      });

      this.paperInfo = result.data;

      await this.getServiceList();
      await this.getPriceAfterAddServices();
      this.paragraphs = result.data.outlines;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoadingPreview = false;
    }
  }

  async getFinalPriview() {
    try {
      this.nextPreviewLoading = true;
      const result = await post("/ai/paper/w", {
        orderNo: this.paperInfo.orderNo,
        subject: "11",
        majorName: this.majorName,
        threeOutline: this.useThreeOutline ? "y" : "n",
        version: this.useUltra ? "ultra" : "d",
        paperOutline: this.paragraphs,
        customReferenceDocument: this.customReference.checked
          ? this.customReference.customReferenceDocument
          : "",
      });
      const preList = this.paperInfo.list || [];

      const list = [...preList, ...result.data.list];

      this.paperInfo.list = list;
      this.paperInfo.orderNo = result.data.orderNo;
      this.hasPreviewEnd = true;
      await Promise.all([this.getPriceAfterAddServices()]);
    } catch (error) {
      console.error(error);
    } finally {
      this.nextPreviewLoading = false;
    }
  }

  updateOutline = async () => {
    try {
      const result = await post("/ai/paper/update/outlines", {
        version: this.useUltra ? "ultra" : "d",
        paperOutline: this.paragraphs,
        orderNo: this.paperInfo.orderNo,
      });

      await Promise.all([this.getPriceAfterAddServices()]);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  onServiceCheck(checked, idx) {
    this.serviceList[idx].checked = checked;
    this.getPriceAfterAddServices();
  }
  async getPriceAfterAddServices() {
    try {
      this.isExtraloading = true;
      const { data } = await post("/ai/paper/add/services", {
        orderNo: this.paperInfo.orderNo,
        ids: this.serviceList
          .filter((s) => s.checked)
          .map((s) => s.id)
          .join(","),
      });

      runInAction(() => {
        this.paperInfo.orderNo = data.orderNo;

        this.paperInfo.price = data.price;
        this.paperInfo.prices = data;
        this.isExtraloading = false;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.isExtraloading = false;
      });
    }
  }
  async getCatogories() {
    try {
      const res = await get("/ai/paper/subject/tree");
      console.log("xx", res);
      this.categories = res.data;
    } catch (error) {
      console.error(error);
    }
  }

  onReferenceCheck(v) {
    this.customReference.checked = v;
  }

  onReferenceChange(e) {
    this.customReference.customReferenceDocument = e.target.value;
  }

  getSelectTypeCount() {
    const data = this.paragraphs;
    const counts = {
      CODE: 0,
      TABLE: 0,
      DIAGRAM: 0,
      FORMULA: 0,
    };
    const countOccurrences = (str, word) =>
      str?.split(",").filter((item) => item.trim() === word).length || 0;

    // 遍历数据，统计每个关键字的出现次数
    data.forEach((chapter) => {
      if (chapter.sections) {
        chapter.sections.forEach((section) => {
          if (section.selected_extra) {
            Object.keys(counts).forEach((key) => {
              counts[key] += countOccurrences(section.selected_extra, key);
            });
          }

          if (section.subsections) {
            section.subsections.forEach((sub) => {
              if (sub.selected_extra) {
                Object.keys(counts).forEach((key) => {
                  counts[key] += countOccurrences(sub.selected_extra, key);
                });
              }
            });
          }
        });
      }
      // 同时统计顶层的selected_extra
      Object.keys(counts).forEach((key) => {
        counts[key] += countOccurrences(chapter.selected_extra, key);
      });
    });

    return counts;
  }
}

const summaryStore = new SummaryStore();
export { summaryStore };

function modifySelectExtra(opt) {
  const { origin, checked, type } = opt;
  const originList = origin?.split(",") || [];
  const isCheckedBefore = originList.includes(type);
  if (!isCheckedBefore && checked) {
    originList.push(type);
    return originList.join(",");
  }
  if (isCheckedBefore && !checked) {
    const idx = originList.indexOf(type);
    originList.splice(idx, 1);
    return originList.join(",");
  }
  return origin;
}

const GetSummaryNotiDom = (txt) => (
  <>
    <p>
      耗时约60s，请不要刷新页面，喝杯咖啡☕️稍作等待。大纲的小节支持<b>增加</b>
      、<b>删除</b>、<b>修改</b>
    </p>
    <p>
      点亮小节 {TableIcon}: 插入<b>数据(表)</b>
    </p>
    <p>
      点亮小节 {PicIcon}: 插入<b>图</b>
    </p>
    <p>
      点亮小节 <CalculatorOutlined style={{ color: "#000" }} />: 插入<b>公式</b>
    </p>
    <p>
      点亮小节 {CodeIcon}: 插入<b>代码</b>
    </p>
    {txt && (
      <p style={{ width: "330px" }}>
        <b>小灵会自动根据您的需要点亮相应的{txt}</b>
        <br />
        您可根据需要进行<b>添加或删减</b>
      </p>
    )}
  </>
);
