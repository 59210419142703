import styles from "./index.module.scss";
import {
  Input,
  Radio,
  Button,
  Switch,
  Tooltip,
  Checkbox,
  Cascader,
  Spin,
  message,
  Modal,
  notification,
  Popover,
} from "antd";
import { useState } from "react";
import {
  QuestionCircleOutlined,
  SyncOutlined,
  SwapOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { LIST, CATEGORIES, MOCK_PREVIEW } from "./const";
import { Summary } from "./Summary";
import { Products } from "./Products";
import { Download } from "./Download";
import { Footer } from "./Footer";
import { observer } from "mobx-react";
import { summaryStore } from "./store";
import { UltraDialog } from "./UltraDialog";
import classNames from "classnames";
import { Preview } from "./Preview/result";
import { Progress } from "./Summary/Progress";
import title from "../../assets/title.png";
import example from "../../assets/example.jpg";
import Img1 from "../../assets/wenxian-1.jpg";
import Img2 from "../../assets/wenxian-2.jpg";
import { isMobile } from "../../common/const";

export const Documents = observer(() => {
  const [selectType, setSelectType] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const [notiApi, notiContextHolder] = notification.useNotification();

  function onChangePolicy(checked) {
    setCheckPolicy(checked);
  }

  async function genAll() {
    try {
      if (!checkPolicy) {
        const config = {
          title: "提示",
          content: (
            <div>
              平台所生成的全文为范文，仅用作参考，不用作毕业论文、发表刊物等
            </div>
          ),
          okText: "同意",
        };
        await modal.warning(config);
        setCheckPolicy(true);
      }
      await summaryStore.getPreview();
      document.scrollingElement.scrollTo(0, 0);
      setShowPreview(true);
    } catch (error) {
      console.error(error);
    }
  }

  if (showPreview && summaryStore.paperInfo) {
    return <Preview />;
  }
  return (
    <div className={styles.documents}>
      {contextHolder}
      {notiContextHolder}
      <div
        className={classNames(styles.bg, {
          [styles.bgUltra]: summaryStore.useUltra,
        })}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img src={title} style={{ height: "60px" }} />
        </div>
        <div className={styles.subTitle}>
          {summaryStore.useUltra
            ? "更专业  更聪明  更流畅"
            : " 动动手指，助你灵感满满"}
        </div>
        <div className={styles.opts}>
          <div
            className={styles.toggleVersion}
            onClick={() => summaryStore.toggleUseUltra()}
            style={{ color: summaryStore.useUltra ? "grey" : "#f7941d" }}
          >
            <SwapOutlined />
            <div className={styles.desc}>
              切换至{summaryStore.useUltra ? "NORMAL" : "ULTRA"}版本
            </div>
            <Tooltip title="点击查看 ULTRA 版本有什么优势？">
              <QuestionCircleFilled
                style={{ color: "#f7941d" }}
                onClick={(e) => {
                  e.stopPropagation();
                  summaryStore.toggleUltraDialog();
                }}
              />
            </Tooltip>
          </div>
          <div className={styles.input}>
            <Cascader
              size="large"
              options={summaryStore.categories}
              placeholder="选择科目"
              style={{ marginRight: "16px" }}
              fieldNames={{
                label: "sname",
                value: "scode",
                children: "children",
              }}
            />

            <Input
              placeholder="请输入论文题目"
              size="large"
              style={{ width: "100%" }}
              value={summaryStore.majorName}
              onChange={(e) => summaryStore.changeMajorName(e.target.value)}
              disabled={summaryStore.isLoadingSummary}
            />
          </div>

          <div
            className={styles.wenxianCheck}
            style={isMobile ? { flexDirection: "column" } : {}}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={styles.mr8}>论文内容需要包含</div>
              <Checkbox.Group
                value={summaryStore.autoLightTypes}
                onChange={(e) => summaryStore.onAutoLightChange(e)}
                disabled={summaryStore.isLoadingSummary}
              >
                <Checkbox value="TABLE">表</Checkbox>

                <Checkbox value="DIAGRAM">图</Checkbox>

                <Checkbox value="FORMULA">公式</Checkbox>

                <Checkbox value="CODE">代码</Checkbox>
              </Checkbox.Group>
              <Tooltip
                color="#fff"
                title={
                  <span style={{ color: "#18191c", width: "250px" }}>
                    您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>
                    <br />
                    在生成大纲后仍可继续进行<hl>添加或删减</hl>
                    <br />
                    点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
                    <img
                      src={example}
                      style={{ color: "#18191c", width: "240px" }}
                    />
                  </span>
                }
              >
                <QuestionCircleOutlined
                  color="#999999"
                  className={styles.summaryIcon}
                />
              </Tooltip>
            </div>

            <div
              className={styles.switchRow}
              style={isMobile ? { marginTop: "8px" } : {}}
            >
              <div className={styles.switchLabel}>自定义文献</div>
              <Switch
                value={summaryStore.customReference.checked}
                onChange={(checked) => summaryStore.onReferenceCheck(checked)}
                disabled={summaryStore.isLoadingSummary}
              />
            </div>
          </div>
          {summaryStore.customReference.checked && (
            <>
              <Input.TextArea
                style={{ marginTop: "10px" }}
                value={summaryStore.customReference.customReferenceDocument}
                onChange={(e) => summaryStore.onReferenceChange(e)}
                maxLength={2000}
                showCount
                size="large"
                type="textarea"
                placeholder="自定义输入，请使用查新引文格式，建议五到六篇文献。"
              />
              <div className={styles.subTitle2}>
                自定义输入文献时，请使用
                <Popover
                  placement="rightTop"
                  content={
                    <div className={styles.wenxianGuide}>
                      <img src={Img1} />
                      <img src={Img2} />
                    </div>
                  }
                  title="文献获取指引"
                >
                  <b>
                    查新引文格式
                    <QuestionCircleOutlined className={styles.icon} />
                  </b>
                </Popover>
              </div>
            </>
          )}
        </div>
        <Button
          className={styles.geneBtn}
          size="large"
          shape="round"
          type="primary"
          onClick={() => summaryStore.getSummary(notiApi, true)}
          loading={summaryStore.isLoadingSummary}
        >
          生成大纲
        </Button>
        {/* <div
          className={styles.thirdSummary}
          onClick={() => summaryStore.toggleThreeOutline()}
        >
          <Switch checked={summaryStore.useThreeOutline} />
          <div className={styles.summaryTip}>使用三级大纲</div>
          <Tooltip title="二级大纲(1.1)：极速简约，结构清晰 三级大纲(1.1.1)：内容丰富，深度思考">
            <QuestionCircleOutlined
              color="#999999"
              className={styles.summaryIcon}
            />
          </Tooltip>
        </div> */}

        <div className={styles.listDesc}>
          {LIST.map((i, idx) => (
            <div className={styles.listItem} key={idx}>
              <span className={styles.listIcon}>{idx + 1}</span>
              <span>{i}</span>
            </div>
          ))}
        </div>

        {summaryStore.isLoadingSummary ? (
          <Progress
            loading={summaryStore.isLoadingSummary}
            end={summaryStore.paragraphs && summaryStore.paragraphs.length}
            desc="小灵正在帮您生成大纲，预计需要等待60s左右，请耐心等待~"
            list={["启动中...", "分析题目", "检索文献", "生成大纲"]}
          />
        ) : null}

        {summaryStore.paragraphs && !summaryStore.isLoadingSummary && (
          <>
            <div id="summaryHeader" className={styles.sectionTitle}>
              编辑大纲，生成全文
            </div>
            <div className={styles.sectionDesc}>
              🔔 点击小节右侧按钮，为该小节增加相应的
              <span>表格，图，公式，代码段</span>
              ，对该小节进行<span>增加、删减、修改</span>
            </div>
            <div className={styles.docTitle}>
              题目：{summaryStore.majorName}
            </div>
            {/* <div className={styles.docType}>科目：11</div> */}
            <Summary />
            <Button
              size="large"
              shape="round"
              ghost
              type="primary"
              className={styles.regenBtn}
              onClick={() => summaryStore.getSummary(notiApi)}
            >
              大纲不满意？重新生成
              <SyncOutlined />
            </Button>
            <div className={styles.policyCheck}>
              <Checkbox
                checked={checkPolicy}
                onChange={(e) => onChangePolicy(e.target.checked)}
              >
                我已阅读并同意：平台所生成的全文为范文，仅用作参考，不用作毕业论文、发表刊物等和
                <a
                  style={{ color: "#666" }}
                  href={`${window.location.protocol}//${window.location.host}/agreement`}
                  target="_blank"
                >
                  《服务条款》
                </a>
              </Checkbox>
            </div>
            <Button
              shape="round"
              type="primary"
              size="large"
              onClick={genAll}
              loading={summaryStore.isLoadingPreview}
              style={{ width: "180px" }}
            >
              预览
            </Button>
            {summaryStore.isLoadingPreview ? (
              <Progress
                loading={summaryStore.isLoadingPreview}
                end={!summaryStore.isLoadingPreview}
                desc="小灵正在帮您生成预览，预计需要等待60s左右，请耐心等待~"
                list={["启动中...", "检索文献", "分析大纲", "生成预览"]}
                distance={600}
              />
            ) : null}
          </>
        )}
        {/* <Download /> */}
        <div className={styles.ringingBox}>
          <p>
            🔔 灵感风暴保护论文隐私安全，采用阿里云安全储存，加密传输，
            <span>不泄漏任何论文信息</span>。
          </p>
          <p>
            🔔 灵感风暴保留论文7天，
            <span>生成7天后自动删除，请及时下载保存</span>。
          </p>
          <p style={{ marginBottom: 0 }}>
            🔔
            所有生成的论文模板只可用作格式参考，不允许抄袭、代写、直接挪用等行为。
          </p>
        </div>
        <Footer />
      </div>
      <UltraDialog />
      <Modal
        title="丰富论文内容"
        open={summaryStore.showCheckTableDialog}
        okText="继续生成大纲"
        footer={
          <Button
            onClick={() => {
              summaryStore.onModalConfirmGetSummary(notiApi);
            }}
            type="primary"
          >
            确认
          </Button>
        }
        closable={false}
      >
        <div>
          <div>
            如您的论文需要包含<hl>表，图，公式，代码</hl>
            ，您可在在下方勾选相应选项，小灵会为您自动生成
            <Tooltip
              color="#fff"
              title={
                <span style={{ color: "#18191c", width: "250px" }}>
                  您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>
                  <br />
                  在生成大纲后仍可继续进行<hl>添加或删减</hl>
                  <br />
                  点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
                  <img
                    src={example}
                    style={{ color: "#18191c", width: "240px" }}
                  />
                </span>
              }
            >
              <QuestionCircleOutlined
                color="#999999"
                className={styles.summaryIcon}
                style={{ marginLeft: "4px" }}
              />
            </Tooltip>
          </div>
          <div
            className={styles.summaryChecks}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox.Group
              value={summaryStore.autoLightTypes}
              onChange={(e) => summaryStore.onAutoLightChange(e)}
              disabled={summaryStore.isLoadingSummary}
            >
              <Checkbox value="TABLE">表</Checkbox>

              <Checkbox value="DIAGRAM">图</Checkbox>

              <Checkbox value="FORMULA">公式</Checkbox>

              <Checkbox value="CODE">代码</Checkbox>
            </Checkbox.Group>
          </div>
          <a href="https://lingganfengbao.com/example" target="_blank">
            查看范文样例
          </a>

          {/* <div style={{ color: "#18191c" }} className={styles.summaryTips}>
            您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>，
            在生成大纲后仍可继续进行<hl>添加或删减</hl>
            ，点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
            <img src={example} style={{ color: "#18191c", width: "240px" }} />
          </div> */}
        </div>
      </Modal>
    </div>
  );
});
