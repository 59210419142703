import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Progress as AProgress, theme } from "antd";
const { useToken } = theme;

export const Progress = (props) => {
  const [progress, setProgress] = useState(0);
  const { token } = useToken();

  // 启动中，分析题目，检索文献，生成大纲
  useEffect(() => {
    let timer;
    if (props.end) {
      setProgress(100);
      clearInterval(timer);
    } else {
      // 每秒增加的进度
      const increment = 1;
      // 设置定时器
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          // 如果进度已经是100%，则清除定时器
          if (prevProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          // 否则递增
          return Math.min(prevProgress + increment, props.loading ? 98 : 100);
        });
      }, props.distance || 500);
    }

    // 清除定时器的清理函数
    return () => clearInterval(timer);
  }, [props.loading, props.end]);

  function getTxt() {
    if (progress < 3) {
      return props.list[0];
    }
    if (progress < 6) {
      return props.list[1];
    }
    if (progress < 10) {
      return props.list[2];
    }
    return props.list[3];
  }

  return (
    <div className={styles.summaryProgress}>
      <AProgress
        type="circle"
        percent={progress}
        format={(percent) => `${getTxt()}\n\n${percent}%`}
        strokeColor={token.colorPrimary}
        size={160}
      />
      <div className={styles.progressTip}>{props.desc}</div>
    </div>
  );
};
