import { Button, Tooltip, Tag } from "antd";
import styles from "./Operator.module.scss";
import { useMemo } from "react";
import { CalculatorOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import cx from "classnames";

const AddIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    className={styles.optIcon}
    style={{ color: "green" }}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 16v3m0 0v3m0-3h3m-3 0h-3m4-10v-.172a2 2 0 0 0-.586-1.414l-3.828-3.828A2 2 0 0 0 14.172 3H14m6 6h-4a2 2 0 0 1-2-2V3m6 6v3m-6-9H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"
    ></path>
  </svg>
);

const EditIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    className={styles.optIcon}
    style={{ color: "rgb(59, 130, 246)" }}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 20h9M16.5 3.5a2.12 2.12 0 0 1 3 3L7 19l-4 1l1-4Z"
    ></path>
  </svg>
);
const DeleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    className={styles.optIcon}
    style={{ color: "rgb(212, 2, 2)" }}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"
    ></path>
  </svg>
);
// 表
const DataIcon = (
  <svg
    style={{ width: "14px", height: "14px" }}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 8V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2M3 8v6m0-6h6m12 0v6m0-6H9m12 6v4a2 2 0 0 1-2 2H9m12-6H9m-6 0v4a2 2 0 0 0 2 2h4m-6-6h6m0-6v6m0 0v6m6-12v12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    ></path>
  </svg>
);
// 流程图
const PicIcon = (
  <svg
    t="1713872268020"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4917"
    width="14"
    height="14"
  >
    <path
      d="M914 652.13h-47.35V504.26c0-20.81-16.87-37.68-37.68-37.68H557.41v-94.72h62.82c20.81 0 37.68-16.87 37.68-37.68V148.65c0-20.81-16.87-37.68-37.68-37.68H434.69c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h47.37v94.72H210.5c-20.81 0-37.68 16.87-37.68 37.68v147.87H110c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h185.54c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68h-47.37V541.94h233.89v110.19h-62.82c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h185.54c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68h-47.37V541.94H791.3v110.19h-62.84c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68H914c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68zM472.37 186.32h110.19v110.19H472.37V186.32z m-214.5 651.36h-110.2v-110.2h110.19v110.2z m309.24 0h-110.2v-110.2H567.1v110.2z m309.22 0h-110.2v-110.2h110.19v110.2z"
      fill="currentColor"
      p-id="4918"
    ></path>
  </svg>
);

const GongshiIcon = (
  <svg
    t="1713872121151"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2393"
    width="14"
    height="14"
  >
    <path
      d="M414.896552 120.712109H169.777065c-29.151564 0-52.554932 23.403368-52.554932 52.554932V418.797113c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932V173.267041c0-28.740978-23.403368-52.554932-52.554932-52.554932z m-13.138733 197.080994H314.303128v87.454692H270.370489V317.793103H182.915798V274.271051h87.454691V186.405774H314.303128v87.454691h87.454691v43.932638z m451.23336-197.080994h-245.119487c-29.151564 0-52.554932 23.403368-52.554932 52.554932V418.797113c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932V173.267041c0-28.740978-23.403368-52.554932-52.554932-52.554932z m-13.138733 197.080994h-218.842021V274.271051H839.852446v43.522052zM414.896552 558.806736H169.777065c-29.151564 0-52.554932 23.403368-52.554932 52.554932v245.119487c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932v-245.119487c0-29.151564-23.403368-52.554932-52.554932-52.554932z m-29.562149 237.318364l-30.793906 30.793906-61.998396-61.998397-61.998396 61.998397-30.793905-30.793906 61.998396-61.998396L199.339214 672.128308l30.793905-30.793905 61.998397 61.998396 61.998396-61.998396 30.793905 30.793905-61.998396 61.998396 62.408982 61.998396z m467.656776-237.318364h-245.119487c-29.151564 0-52.554932 23.403368-52.554932 52.554932v245.119487c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932v-245.119487c0-29.151564-23.403368-52.554932-52.554932-52.554932z m-34.89976 241.013633H642.771451v-43.932638h175.319968v43.932638z m0-87.865277H642.771451v-43.932638h175.319968v43.932638z m0 0"
      fill="currentColor"
      p-id="2394"
    ></path>
  </svg>
  // <div>gs</div>
);

const CodeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g fill="none">
      <path d="M0 0h24v24H0z"></path>
      <path
        fill="currentColor"
        d="M14.486 3.143a1 1 0 0 1 .692 1.233l-4.43 15.788a1 1 0 0 1-1.926-.54l4.43-15.788a1 1 0 0 1 1.234-.693M7.207 7.05a1 1 0 0 1 0 1.414L3.672 12l3.535 3.535a1 1 0 1 1-1.414 1.415L1.55 12.707a1 1 0 0 1 0-1.414L5.793 7.05a1 1 0 0 1 1.414 0m9.586 1.414a1 1 0 1 1 1.414-1.414l4.243 4.243a1 1 0 0 1 0 1.414l-4.243 4.243a1 1 0 0 1-1.414-1.415L20.328 12z"
      ></path>
    </g>
  </svg>
);

/** 判断额外按钮的状态 */
function judgeIcon(data, type) {
  const TYPES_DESC = {
    CODE: "代码",
    TABLE: "数据(表)",
    DIAGRAM: "图",
    FORMULA: "公式",
  };
  const { extraType = "", selected_extra = "", extraTypeCn } = data || {};
  if (!extraType)
    return {
      canShow: false,
    };
  const canShow = extraType?.split(",").includes(type);
  const have = selected_extra?.split(",").includes(type);
  const tip = !have
    ? "点亮图标，本节插入" + TYPES_DESC[type]
    : extraTypeCn && extraTypeCn[type]
    ? extraTypeCn[type]
    : "本节插入" + TYPES_DESC[type];
  return {
    canShow,
    have,
    tip,
  };
}

export const Operator = observer((props) => {
  const { onEdit, onAdd, onDelete, isLastLevel, data, onChangeExtra } = props;
  const { extraType, selected_extra, extraTypeCn } = data || {};

  const codeIcon = useMemo(() => {
    const { canShow, have, tip } = judgeIcon(
      { extraType, selected_extra, extraTypeCn },
      "CODE"
    );
    if (!canShow) return null;
    return (
      <Tooltip title={tip}>
        <div
          className={cx(styles.iconBtn, have ? styles.iconActive : "")}
          onClick={() => onChangeExtra?.({ checked: !have, type: "CODE" })}
        >
          {CodeIcon}
        </div>
      </Tooltip>
    );
  }, [extraType, selected_extra, extraTypeCn, onChangeExtra]);

  const dataIcon = useMemo(() => {
    const { canShow, have, tip } = judgeIcon(
      { extraType, selected_extra, extraTypeCn },
      "TABLE"
    );
    if (!canShow) return null;
    return (
      <Tooltip title={tip}>
        <div
          className={cx(styles.iconBtn, have ? styles.iconActive : "")}
          onClick={() => onChangeExtra?.({ checked: !have, type: "TABLE" })}
        >
          {DataIcon}
        </div>
      </Tooltip>
    );
  }, [extraType, selected_extra, extraTypeCn, onChangeExtra]);

  // 流程图
  const diagramIcon = useMemo(() => {
    const { canShow, have, tip } = judgeIcon(
      { extraType, selected_extra, extraTypeCn },
      "DIAGRAM"
    );
    if (!canShow) return null;
    return (
      <Tooltip title={tip}>
        <div
          className={cx(styles.iconBtn, have ? styles.iconActive : "")}
          onClick={() => onChangeExtra?.({ checked: !have, type: "DIAGRAM" })}
        >
          {PicIcon}
        </div>
      </Tooltip>
    );
  }, [extraType, selected_extra, extraTypeCn, onChangeExtra]);

  const formulaIcon = useMemo(() => {
    const { canShow, have, tip } = judgeIcon(
      { extraType, selected_extra, extraTypeCn },
      "FORMULA"
    );
    if (!canShow) return null;
    return (
      <Tooltip title={tip}>
        <div
          className={cx(styles.iconBtn, have ? styles.iconActive : "")}
          onClick={() => onChangeExtra?.({ checked: !have, type: "FORMULA" })}
        >
          {GongshiIcon}
        </div>
      </Tooltip>
    );
  }, [extraType, selected_extra, extraTypeCn, onChangeExtra]);

  return (
    <div className={styles.opt}>
      {isLastLevel && (
        <>
          {dataIcon}
          {diagramIcon}
          {formulaIcon}
          {codeIcon}
        </>
      )}
      <Tooltip title="添加章节">
        <Button
          type="text"
          className={styles.iconBtn}
          icon={AddIcon}
          onClick={(e) => {
            e.stopPropagation();
            onAdd?.();
          }}
        />
      </Tooltip>
      <Tooltip title="编辑章节">
        <Button
          className={styles.iconBtn}
          type="text"
          icon={EditIcon}
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.();
          }}
        />
      </Tooltip>

      <Tooltip title="删除章节">
        <Button
          className={styles.iconBtn}
          type="text"
          icon={DeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.();
          }}
        />
      </Tooltip>
    </div>
  );
});
