import styles from "./result.module.scss";
import { Modal, QRCode, Button, Alert, message, Input, Spin } from "antd";
import { useState, useEffect, useRef, useCallback } from "react";
import error from "../../../assets/error.png";
import success from "../../../assets/success.png";
import { get, post, baseURL } from "../../../axios/axios";
import { observer } from "mobx-react";
import { useEventSource } from "../api";

import { Checkbox, Carousel, Drawer } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { summaryStore } from "../store";
import { Products } from "../Products";
import { globalData } from "../../../Service/utils";
import { DownloadOrderModal } from "../../../components/Order/DownloadOrderModal";
import { Summary } from "../Summary";
import useCountdown from "@bradgarropy/use-countdown";

export const Preview = observer(() => {
  const paper = summaryStore.paperInfo || {};
  const [showPayModal, setShowPayModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [timerId, setTimerId] = useState("");
  const [payResult, setPayResult] = useState(false);
  const payInfoRef = useRef({});
  const [showPayResult, setShowPayResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [demoList, setDemoList] = useState([]);
  const counts = summaryStore.getSelectTypeCount();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const showDrawer = useCallback((e) => {
    e.stopPropagation();
    setDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerVisible(false);
  }, []);
  // 中文摘要
  const { startFetch, status: streamStatus } = useEventSource();
  const chineseSummary = useRef("");
  const englishSummary = useRef("");
  const previewEnd = useRef(false);

  // 英文摘要
  const {
    startFetch: startFetchEnglish,
    // text: englishSummary,
    status: englishStatus,
  } = useEventSource();
  const appendText = (elId, text) => {
    const el = document.getElementById(elId);
    if (el) {
      el.innerText = text;
    }
  };

  useEffect(() => {
    startFetch(
      `${baseURL}ai/paper/gen/c/abstract`,
      JSON.stringify({ orderNo: paper.orderNo }),
      (...params) => {
        const [word, index] = params;
        chineseSummary.current += word;
        appendText("cContent", chineseSummary.current);
      },
      () => {
        setTimeout(() => {
          startFetchEnglish(
            `${baseURL}ai/paper/gen/e/abstract`,
            JSON.stringify({ orderNo: paper.orderNo }),
            (...params) => {
              const [word, index] = params;
              englishSummary.current += word;
              appendText("eContent", englishSummary.current);
            },
            () => {
              previewEnd.current = true;
            }
          );
        }, 2000);
      }
    );
  }, []);

  const submitPay = async () => {
    try {
      if (loading) {
        return;
      }

      setLoading(true);
      const client = "PC";
      const res = await post("/member/payment/pay", {
        orderNo: paper.orderNo,
        client: client,
      });

      if (client === "H5") {
        window.location.href = res.data.mwebUrl;
      } else {
        globalData.destroy = 0;
        payInfoRef.current = {
          ...(res.data || {}),
        };
        setShowPayModal(true);

        checkPay({ force: true });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const showCodeModal = async (e) => {
    e.stopPropagation();
    setShowRedeemModal(true);
  };

  const [submitRedeemLoading, setSubmitRedeemLoading] = useState(false);
  const submitRedeemCode = async () => {
    try {
      setSubmitRedeemLoading(true);
      const res = await post("/member/payment/redeemCode", {
        orderNo: paper.orderNo,
        code: redeemCode,
      });
      console.log("res", res);
      if (res && res.code === 0) {
        message.info("正在加速生成中，预计需要30分钟");
        setShowRedeemModal(false);
        setPayResult(true);
      } else {
        const msg = res && res.msg;
        message.error(
          msg || "请检查您输入的兑换码是否正确，如果错误请重新输入。"
        );
      }
    } catch (error) {
      console.error(error);
      // message.error("请检查您输入的兑换码是否正确，如果错误请重新输入。");
    } finally {
      setSubmitRedeemLoading(false);
    }
  };

  const onCancel = () => {
    window.clearTimeout(timerId);
    payInfoRef.current = {};
    setShowPayResult(false);
    setShowPayModal(false);
    globalData.destroy = 1;
  };

  const toRePay = async () => {
    setShowPayResult(false);
    const res = await post("/member/payment/pay", {
      orderNo: paper.orderNo,
      client: "PC",
    });

    payInfoRef.current = res.data || {};
  };

  const checkPay = async (options = {}) => {
    if (timerId) {
      window.clearTimeout(timerId);
    }
    if (globalData.destroy) {
      return;
    }

    try {
      const res = await get("/member/payment/pay/result/" + paper.orderNo);
      if (res && res.data && res.data.code === 0) {
        setShowPayResult(true);
        setPayResult(res.data.code === 0);

        setTimeout(() => {
          if (res.data.code === 0) {
            onCancel();
          }
        }, 1000);
      } else {
        const id = window.setTimeout(() => {
          checkPay();
        }, 1000);
        setTimerId(id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function openUrl(url) {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
  }

  async function getPayDemo() {
    const { data } = await get("/ai/paper/r");
    setDemoList(data);
  }
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const showOrderModal = () => {
    setIsOrderModalOpen(true);
  };

  const handleOrderModalOk = () => {
    setIsOrderModalOpen(false);
  };

  const handleOrderModalCancel = () => {
    setIsOrderModalOpen(false);
  };

  function getHtmlContent(text) {
    // 匹配 \textsuperscript{[数字]} 的模式
    const regex = /\\ccccc\{\[(\d+)\]\}/g;
    // 使用 \$1 来引用第一个括号中捕获的内容（即数字），并在替换文本中保留方括号
    return text.replace(regex, "<span>[$1]</span>");
  }

  const [updateLoading, setUpdateLoading] = useState(false);

  const updateOutlineCallback = useCallback(async () => {
    setUpdateLoading(true);
    await summaryStore.updateOutline();
    closeDrawer();
    message.success("修改成功");
    setUpdateLoading(false);
  }, []);

  const countdown = useCountdown({
    minutes: 0,
    seconds: 30 * 60,
    format: "mm:ss",
    autoStart: false,
    onCompleted: () => console.log("onCompleted"),
  });

  useEffect(() => {
    if (showPayModal) {
      countdown.start();
    }
  }, [showPayModal, countdown]);

  return (
    <div className={styles.result}>
      <div className={styles.resultPage}>
        <div className={styles.pageTitle}>论文</div>
        <div>
          <div className={styles.chapterTitle}>中文摘要</div>
          <div className={styles.content} id="cContent"></div>
        </div>
        {englishSummary.current && englishSummary.current.length ? (
          <div>
            <div className={styles.chapterTitle}>英文摘要</div>
            <div id="eContent" className={styles.content}></div>
          </div>
        ) : null}
        {previewEnd.current ? null : (
          <Spin tip="生成中" style={{ margin: "0 auto" }}>
            <div style={{ height: "160px", width: "100%" }}></div>
          </Spin>
        )}

        <div>
          {paper.list &&
            paper.list.map((item, idx) => (
              <div key={idx}>
                <div className={styles.chapterTitle}>{item.chapter}</div>
                <div className={styles.section}>{item.section}</div>
                <div className={styles.subHeading}>{item.subheading}</div>
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlContent(item.content),
                  }}
                ></div>
              </div>
            ))}
        </div>

        <div className={styles.payButtonBox}>
          <div className={styles.payMask}></div>

          <div>
            {payResult ? (
              <div className="flex" style={{ flexDirection: "column" }}>
                <Button
                  onClick={showOrderModal}
                  type="primary"
                  size="large"
                  shape="round"
                >
                  下载论文
                </Button>
              </div>
            ) : (
              previewEnd.current && (
                <div className="flex" style={{ flexDirection: "column" }}>
                  <div className="flex">
                    {summaryStore.hasPreviewEnd ? null : (
                      <Button
                        loading={summaryStore.nextPreviewLoading}
                        size="large"
                        shape="round"
                        onClick={() => summaryStore.getFinalPriview()}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                          width: "160px",
                        }}
                        disabled={!previewEnd.current}
                      >
                        继续预览
                      </Button>
                    )}

                    <Button
                      type="primary"
                      loading={loading || summaryStore.isExtraloading}
                      size="large"
                      shape="round"
                      onClick={submitPay}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "160px",
                      }}
                      disabled={!previewEnd.current}
                    >
                      <div>
                        ￥
                        {summaryStore.paperInfo?.prices?.totalDiscountedPrice ||
                          ""}
                        解锁全篇
                      </div>
                    </Button>
                  </div>
                  <div className={styles.payTip}>
                    <div
                      style={{
                        color: "#999",
                        fontSize: "12px",
                        marginTop: "8px",
                        cursor: "pointer",
                      }}
                      onClick={showDrawer}
                    >
                      价格太贵？点击修改图表
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          {demoList && demoList.length ? (
            <div className={styles.demoList}>
              <NotificationOutlined style={{ color: "rgb(36, 190, 88)" }} />
              <Carousel
                style={{ width: "300px", margin: "0 0 0 10px" }}
                dotPosition="left"
                autoplay={true}
                autoplaySpeed={1000}
              >
                {demoList.map((item, idx) => {
                  return <div key={idx}>{item}</div>;
                })}
              </Carousel>
            </div>
          ) : null}
        </div>

        <Spin spinning={summaryStore.nextPreviewLoading}>
          <Products end={previewEnd.current} />
        </Spin>
        {previewEnd.current ? (
          <div className={styles.payTip} style={{ margin: "0 auto" }}>
            <div
              style={{
                color: "#999",
                fontSize: "12px",
                marginTop: "8px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={showCodeModal}
            >
              我有兑换码
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        title="解锁全文"
        open={showPayModal}
        width={790}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        {showPayResult ? (
          <div className={styles.payResult}>
            <div
              className={styles.payStatusBox}
              style={payResult ? { padding: "16px 0 20px 0" } : {}}
            >
              <p className={styles.statusTitle}>
                {payResult ? "恭喜您，支付成功" : "很遗憾，支付失败"}
              </p>
              <img
                src={payResult ? success : error}
                className={styles.statusImg}
                style={payResult ? { margin: "16px 0 20px 0" } : {}}
              />
              {!payResult ? (
                <Button className="action_btn" onClick={toRePay}>
                  <span>重新支付</span>
                </Button>
              ) : (
                <Button onClick={onCancel}>
                  <span>完成</span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "30px 0 0 0" }}>
            <Alert
              message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
              type="warning"
              showIcon
              className="w-pay-alert"
            />
            <div className={styles.payContent}>
              <div className={styles.left}>
                <div className={styles.qrcode}>
                  <QRCode value={payInfoRef.current.codeUrl} />
                </div>
                <div>微信扫码支付</div>
                <div style={{ color: "#999", fontSize: "12px" }}>
                  如遇支付问题，请联系客服微信
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.title}>{summaryStore.majorName}</div>
                {countdown.formatted !== "00:00" ? (
                  <div className={styles.discount}>
                    <div className={styles.line}>
                      <div className={styles.lineLeft}></div>
                      <div className={styles.lineRight}></div>
                    </div>
                    <div>
                      限时特惠 距活动结束：
                      <span className={styles.countdown}>
                        {countdown.formatted}
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className={styles.row}>
                  <label>订单总额：</label>
                  <div>
                    <span className={styles.price}>
                      ￥{paper.prices && paper.prices.totalDiscountedPrice}
                      <span style={{ fontSize: "16px" }}>元</span>
                    </span>
                    <span className={styles.origin}>
                      ￥{paper.prices && paper.prices.totalOriginalPrice}元
                    </span>
                  </div>
                </div>
                <div className={styles.row}>
                  <label>论文金额：</label>
                  <div>
                    <div>
                      <span className={styles.price}>
                        ￥{paper.prices.paperDiscountedPrice}
                        <span style={{ fontSize: "16px" }}>元</span>
                      </span>
                      <span className={styles.origin}>
                        ￥{paper.prices.paperOriginalPrice}元
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <label>专业内容：</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={styles.price}>
                      ￥{paper.prices.contentServiceDiscountedPrice}元
                      <span className={styles.origin}>
                        ￥{paper.prices.contentServiceOriginalPrice}元
                      </span>
                    </div>
                    <div style={{ color: "#666", fontSize: "12px" }}>
                      {counts.TABLE ? `[数据(表)x${counts.TABLE}]` : null}
                      {counts.DIAGRAM ? ` [图x${counts.DIAGRAM}]` : null}
                      {counts.FORMULA ? `[公式x${counts.FORMULA}]` : null}
                      {counts.CODE ? `[代码段x${counts.CODE}]` : null}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.row}
                  style={{ alignItems: "flex-start" }}
                >
                  <label>附加服务：</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={styles.price}>
                      ￥{paper.prices.additionalServiceDiscountedPrice}元
                      <span className={styles.origin}>
                        ￥{paper.prices.additionalServiceOriginalPrice}元
                      </span>
                    </div>
                    <div style={{ color: "#666", fontSize: "12px" }}>
                      {summaryStore.serviceList
                        .filter((it) => it.checked)
                        .map((i, idx) => (
                          <span key={idx}>[{i.sname}x1]</span>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.btnRow}>
                  <Button
                    onClick={checkPay}
                    className={styles.btn}
                    type="primary"
                    shape="round"
                  >
                    我已支付成功
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={showRedeemModal}
        width={790}
        footer={null}
        onCancel={() => setShowRedeemModal(false)}
        maskClosable={false}
        title="请输入兑换码"
      >
        <Input
          style={{ marginTop: "16px" }}
          placeholder="请输入兑换码"
          value={redeemCode}
          onChange={(e) => setRedeemCode(e.target.value)}
        />
        <div
          style={{ marginTop: "20px", justifyContent: "flex-end" }}
          className="flex"
        >
          <Button
            loading={submitRedeemLoading}
            type="primary"
            onClick={submitRedeemCode}
          >
            确定
          </Button>
        </div>
      </Modal>

      <Modal
        title="下载论文"
        open={isOrderModalOpen}
        onOk={handleOrderModalOk}
        onCancel={handleOrderModalCancel}
        footer={null}
        destroyOnClose
      >
        <DownloadOrderModal orderNo={paper.orderNo} />
      </Modal>

      <Drawer
        title="修改大纲"
        rootClassName="summary-drawer"
        onClose={closeDrawer}
        open={drawerVisible}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              lineHeight: 1,
              height: "auto",
            }}
          >
            <Button onClick={closeDrawer}>取消</Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={updateOutlineCallback}
              loading={updateLoading}
            >
              确定
            </Button>
          </div>
        }
      >
        <Summary from="drawer" />
      </Drawer>
    </div>
  );
});
