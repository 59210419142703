import styles from "./index.module.scss";
import { Button, Divider, Input, Spin, message } from "antd";
import { useState } from "react";
import { get } from "../../axios/axios";
import orderDemo from "../../assets/order-demo.jpg";
export const OrderModal = (props) => {
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  function onChange(e) {
    setOrder(e.target.value);
    setInfo(undefined);
  }

  const [info, setInfo] = useState();
  async function queryOrder() {
    try {
      setLoading(true);
      setInfo(undefined);
      const info = await get("/ai/paper/query", { orderNo: order });
      console.log("xx", info);
      setInfo(info.data);
      if (
        info.data &&
        info.data.list.filter((i) => i.downloadUrl).length <
          info.data.list.length
      ) {
        message.info("正在加速生成中，预计需要30分钟全部完成");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function download(url) {
    if (!url) {
      return;
    }
    window.open(url, "_blank");
  }

  return (
    <div className={styles.orderModal}>
      <div>
        <p>请输入微信支付记录中的订单号/交易单号</p>
        <Input value={order} onChange={onChange} />
      </div>

      <Spin spinning={loading}>
        {info ? (
          <div className={styles.content}>
            <div className={styles.header}>
              <div>订单号：{order}</div>
              <div>{info.createTime}</div>
            </div>
            <div className={styles.title}>论文名称：{info.paperTitle}</div>
            <Divider className={styles.divider} />

            <div className={styles.list}>
              {info.list.map((l, idx) => {
                return (
                  <div className={styles.listRow} key={idx}>
                    <div className={styles.listTitle}>{l.title}</div>
                    <div>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => download(l.downloadUrl)}
                      >
                        {l.downloadUrl ? "下载" : "进行中"}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.tips}>
              温馨提示：
              <div>1.为了产出更高质量的文档，预计30分钟左右生成完成</div>
              <div>
                2.文件下载流程：微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
              </div>
              <img src={orderDemo} style={{ width: "auto", height: "200px" }} />
              <div>
                3.所有论文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
              </div>
            </div>
          </div>
        )}
      </Spin>

      <div className={styles.footer}>
        <Button
          loading={loading}
          type="primary"
          shape="round"
          onClick={queryOrder}
        >
          查询订单
        </Button>
      </div>
    </div>
  );
};
