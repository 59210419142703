import React, { useEffect, useMemo, useState } from "react";
import { Button, Layout, Menu, message, Popover } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styles from "./index.module.scss";
import { Nav } from "../components/Nav/index";
import { Pay } from "./Pay";

export function Article() {
  const [qs] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  let { pathname } = useLocation();
  const [showPay, setShowPay] = useState(qs.get("code") && qs.get("state"));

  const nav = useNavigate();

  return (
    <>
      {contextHolder}
      <Layout style={{ minHeight: "100vh", background: "#fff" }}>
        {showPay ? (
          <Pay
            orderNo={qs.get("state")}
            code={qs.get("code")}
            state={qs.get("state")}
          />
        ) : (
          <>
            <Nav />
            <Outlet />
          </>
        )}
      </Layout>
    </>
  );
}
