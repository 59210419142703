import styles from "./CardBox.module.scss";
import cx from "classnames";

export const CardBox = (props) => {
  return (
    <div
      className={cx([styles.zhengwenbox, props.className])}
      style={{ borderColor: props.color }}
    >
      <div className={styles.left} style={{ color: props.color }}>
        {props.icon}
      </div>

      <div className={styles.right}>
        <div>{props.title}</div>
        <div className={styles.desc}>{props.desc}</div>
        <div className={styles.check}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            className="text-[#01847f] text-xl iconify iconify--openmoji"
            width="1em"
            height="1em"
            viewBox="0 0 72 72"
          >
            <path
              fill="#b1cc33"
              d="m61.5 23.3l-8.013-8.013l-25.71 25.71l-9.26-9.26l-8.013 8.013l17.42 17.44z"
            ></path>
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M10.5 39.76L27.92 57.2L61.5 23.31l-8.013-8.013l-25.71 25.71l-9.26-9.26z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};
