import styles from "./index.module.scss";
import { Button, Divider, Input, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { get } from "../../axios/axios";

export const DownloadOrderModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState();
  async function queryOrder() {
    try {
      setLoading(true);
      const info = await get("/ai/paper/query", { orderNo: props.orderNo });
      setInfo(info.data);
      if (
        info.data &&
        info.data.list.filter((i) => i.downloadUrl).length <
          info.data.list.length
      ) {
        message.info("正在加速生成中，预计需要30分钟全部完成");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function download(url) {
    if (!url) {
      message.info("正在加速生成中，预计需要30分钟全部完成");
      return;
    }
    window.open(url, "_blank");
  }

  useEffect(() => {
    queryOrder();
  }, []);

  return (
    <div className={styles.orderModal}>
      <Spin spinning={loading}>
        {info ? (
          <div className={styles.content}>
            <div className={styles.header}>
              <div>订单号：{props.orderNo}</div>
              <div>{info.createTime}</div>
            </div>
            <div className={styles.title}>论文名称：{info.paperTitle}</div>
            <Divider className={styles.divider} />

            <div className={styles.list}>
              {info.list.map((l, idx) => {
                return (
                  <div className={styles.listRow} key={idx}>
                    <div className={styles.listTitle}>{l.title}</div>
                    <div>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => download(l.downloadUrl)}
                      >
                        {l.downloadUrl ? "下载" : "进行中"}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div style={{ height: "20px" }}></div>
        )}
      </Spin>
    </div>
  );
};
