import styles from "./index.module.scss";
import panda from "../../../assets/error.png";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.box}>
          <div className={styles.imgBox}>
            <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbgzh.png" />
          </div>
          <div>关注公众号，获取更多论文技巧</div>
        </div>
        <div className={styles.box}>
          <div className={styles.imgBox}>
            <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbqq.png" />
          </div>
          <div>灵感风暴官方QQ群</div>
        </div>
      </div>
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        className={styles.aboutUs}
        target="_blank"
      >
        皖ICP备2023021108号-2 LINGGANFENGBAO.COM
      </a>
    </div>
  );
};
