import styles from "./index.module.scss";
import logoN from "../../assets/logo-normal.png";
import logoU from "../../assets/logo-ultra.png";
import { Button, Tooltip, Modal, Input } from "antd";
import { useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { OrderModal } from "../Order/OrderModal";

import { summaryStore } from "../../Article/Documents/store";
export const Nav = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nav = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toHome = () => {
    // summaryStore.reset();
    nav("/");
    window.document.scrollingElement?.scrollTo(0, 0);
    window.location.reload();
  };

  const toExample = () => {
    nav("/example");
  };

  return (
    <div className={styles.nav}>
      <img
        src={summaryStore.useUltra ? logoU : logoN}
        className={styles.logo}
        onClick={toHome}
      />
      <div className={styles.content}>
        <div className={styles.item} onClick={showModal}>
          我的订单
        </div>
        <div className={styles.item} onClick={toExample}>
          范文样例
        </div>
      </div>
      <Modal
        title="我的订单"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <OrderModal />
      </Modal>
    </div>
  );
};
