import styles from "./index.module.scss";
import { Checkbox, Spin, theme } from "antd";
import { TranslationOutlined, BookOutlined } from "@ant-design/icons";
import { CardBox } from "./CardBox";
import { observer } from "mobx-react";
import { summaryStore } from "../store";
import { useCallback } from "react";
const { useToken } = theme;

export const Products = observer((props) => {
  const { token } = useToken();

  return (
    <div className={styles.products}>
      <div className={styles.title}>您将获得</div>
      <CardBox
        title={`[正文]${summaryStore.majorName}`}
        color={token.colorPrimary}
        icon={
          <svg
            data-v-8d803cf7=""
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M20 12v5c0 1.886 0 2.828-.586 3.414C18.828 21 17.886 21 16 21H6.5a2.5 2.5 0 0 1 0-5H16c1.886 0 2.828 0 3.414-.586C20 14.828 20 13.886 20 12V7c0-1.886 0-2.828-.586-3.414C18.828 3 17.886 3 16 3H8c-1.886 0-2.828 0-3.414.586C4 4.172 4 5.114 4 7v11.5"></path>
              <path
                strokeLinecap="round"
                d="m9 10l1.293 1.293a1 1 0 0 0 1.414 0L15 8"
              ></path>
            </g>
          </svg>
        }
      ></CardBox>
      <div className={styles.grid}>
        <CardBox
          className={styles.gridItem}
          title="[中英文摘要]"
          desc="x1"
          color="#52991B"
          icon={<TranslationOutlined />}
        />
        <CardBox
          className={styles.gridItem}
          title="[中英参考文献]"
          desc="x30"
          color="#52991B"
          icon={<BookOutlined />}
        />
        <CardBox
          className={styles.gridItem}
          title="[致谢模板]"
          desc="x5"
          color="#52991B"
          icon={
            <svg
              data-v-a7e0ac5f=""
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l3.235 1.94a2.8 2.8 0 0 0-.233 1.027L1 5.384v5.721l3.453-2.124q.219.416.55.835l-3.97 2.443A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741l-3.968-2.442q.33-.421.55-.836L15 11.105V5.383l-3.002 1.801a2.8 2.8 0 0 0-.233-1.026L15 4.217V4a1 1 0 0 0-1-1zm6 2.993c1.664-1.711 5.825 1.283 0 5.132c-5.825-3.85-1.664-6.843 0-5.132"
              ></path>
            </svg>
          }
        ></CardBox>
      </div>
      {summaryStore.serviceList &&
        summaryStore.serviceList.length > 0 &&
        props.end && (
          <Spin spinning={summaryStore.isExtraloading}>
            <div className={styles.title}>附加服务</div>
            <div className={styles.extraBox}>
              {summaryStore.serviceList.map((service, idx) => {
                return (
                  <div key={idx + service.id} className={styles.extra}>
                    <div style={{ position: "relative" }}>
                      <div className={styles.name}>{service.sname}</div>
                      <div className={styles.desc}>
                        <span className={styles.price}>{service.price}元</span>
                        <span className={styles.origin}>
                          {service.price2}元
                        </span>
                      </div>
                    </div>
                    <Checkbox
                      style={{ position: "relative" }}
                      checked={service.checked}
                      onChange={(e) =>
                        summaryStore.onServiceCheck(e.target.checked, idx)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Spin>
        )}
    </div>
  );
});
