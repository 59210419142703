import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import styles from "./index.module.scss";
import panda from "../../assets/error.png";

export function ErrorBoundary() {
  let error = useRouteError() || {};
  console.error(error.message);
  // Uncaught ReferenceError: path is not defined
  return (
    <div className={styles.error}>
      <img src={panda} className={styles.img} />
      <div>
        建议您使用最新版Chrome浏览器获取最佳写作体验, 如还有问题可联系客服处理
      </div>
      <div className={styles.tip}>{error.message}</div>
    </div>
  );
}
