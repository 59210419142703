import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { loginInstance } from "../../Service/login";
import { SSE } from "sse.js";
import { message } from "antd";
async function sleep(ts = 3000) {
  return new Promise((res) => {
    setTimeout(res, ts);
  }, []);
}

export function eventSource(url, cb, cb2, payload, singleWordCb) {
  const h = payload ? { "Content-Type": "application/json" } : {};
  const source = new SSE(url, {
    headers: {
      ...h,
    },
    payload,
  });
  const text = [];
  let delay = 0;

  source.onopen = () => {
    console.log("连接成功");
  };
  source.onmessage = async function (event) {
    const data = JSON.parse(event.data);
    if (!data || !data.chatToken) {
      return;
    }
    let { chatToken: token, index } = data;
    token = token.replace(/%/g, "%25");
    token = decodeURIComponent(token);
    if (token === "[DONE]") {
      await sleep(10 * delay);
      cb2();
      source.close();
      return;
    }
    if (index === undefined) {
      return;
    }
    if (text[index]) {
      text[index] += token;
    } else {
      text[index] = token;
    }

    cb(text);
    delay = delay + 1;
    await sleep(10 * delay);
    singleWordCb && singleWordCb(token, index);
  };
  source.onerror = (e) => {
    console.error("连接失败", e);

    cb2();

    source.close();
    // show error
    try {
      const errorData = JSON.parse(e.data);
      message.error(errorData.msg);
    } catch (error) {}
  };
  source.stream();
}

export function useEventSource() {
  const text = useRef([]);
  const [ts, setTS] = useState(0);
  const [status, setStatus] = useState("init");

  const startFetch = useCallback(
    async (url, payload, singleWordCb, finishCb) => {
      text.current = [];
      setStatus("loading");
      eventSource(
        url,
        (t) => {
          text.current = [...t];
          setTS(Date.now());
        },
        () => {
          setStatus("done");
          finishCb && finishCb();
        },
        payload,
        singleWordCb
      );
    },
    []
  );

  return {
    startFetch,
    text: text.current,
    status,
  };
}

export function commonEventSource(url, cb, cb2, payload, singleWordCb) {
  const h = payload ? { "Content-Type": "application/json" } : {};
  const source = new SSE(url, {
    headers: {
      ...h,
    },
    payload,
  });
  const text = [];
  source.onopen = () => {
    console.log("连接成功");
  };
  source.onmessage = function (event) {
    const data = JSON.parse(event.data);
    if (!data || !data.chatToken) {
      return;
    }
    let { chatToken: token, index } = data;
    if (token === "[DONE]") {
      cb2();
      source.close();
      return;
    }
    if (index === undefined) {
      return;
    }
    token = token.replace(/%/g, "%25");
    token = decodeURIComponent(token);
    if (text[index]) {
      text[index] += token;
    } else {
      text[index] = token;
    }

    singleWordCb && singleWordCb(token, index);
    cb(text);
  };
  source.onerror = (e) => {
    console.error("连接失败", e);

    source.close();
    // show error
    try {
      const errorData = JSON.parse(e.data);
      message.error(errorData.msg);
      cb2({ status: "error", msg: errorData.msg });
    } catch (error) {}
  };
  source.stream();

  return source;
}
